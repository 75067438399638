<!--
 * @Author: your name
 * @Date: 2021-04-01 10:16:10
 * @LastEditTime: 2021-04-19 17:15:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\GkfxIndex\GkfxIndex.vue
-->
<template>
  <div class='GkfxIndex'>
      <div class="gkfx-header">
        <div class="line">
            <span class='class-blod'>复习段：</span>
            <div class='gkfx-filter' :class='{"menu-active": index == periodIndex}' v-for='(item, index) in periodList' @click="onClickPeriod(item,index)" :key="index" v-show="!item.isHide">
                <span>
                    {{ item.RevisePeriodName }}
                </span>
            </div>
        </div>
      </div>
      <series-list ref="series" v-if="isValidArray(periodList)" :fuxiPeriod="periodList[periodIndex].RevisePeriodID"></series-list>
  </div>
</template>
<script>
import SeriesList from '../SunSpecialRes/Index'
export default {
  name: 'GkfxIndex',
  props:[],
  components: {SeriesList},
  data () {
    return {
      periodIndex:0,
      periodList:[]
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.getCourseRevisePeriod()
  },
  methods: {
    getCourseRevisePeriod(){//获取复习段
      const period = this.getSessionVal('sc_period');

      // 显成说 精准区 120449/外正 121346/外测 -> 23 120276/预发布，其他区是 3
      // const zoneId = this.loadAccount().zoneidx;
      // 【【罗湖授权】后台创建的教辅   页面没有显示】https://www.tapd.cn/58933855/bugtrace/bugs/view?bug_id=1158933855001028494==修改courseID为菜单id
      const course = JSON.parse(sessionStorage.getItem('courseID'));

      // let api = `api/CourseRevisePeriod?PeriodID=${this.getSunAccount('PeriodID')}&CourseID=3`
      // let api = this.getMasterDataCenterUrl() + `/api/CourseRevisePeriod/GetCourseRevisePeriodList?PeriodID=3&CourseID=3`; // get
      let api = this.getMasterDataCenterUrl() + `/api/CourseRevisePeriod/GetCourseRevisePeriodAndSeriesCountList`; // post
      let params = {
        "getRevisePeriodQuery": {
          "periodID": period,
          "courseID": course
        },
        "getSeriesListQuery": {
          "periodID": period,
          "subjectID": sessionStorage.getItem('sc_subject'),
          "termID": 0,
          "zoneID": sessionStorage.getItem('sc_zone'),
          "courseID": course,
          "schoolID": 0,
          "revisePeriodID": 0
        }
      }

      console.log(this.$store.state);
      this.$http.post(api,params).then(res=>{
        let data = res.data.data
        for (let i = 0; i < data.length; i++) {
            data[i] = this.toUpperCase(data[i])
            for (let j = 0; j < data[i].RevisePeriodList.length; j++) {
                let element = data[i].RevisePeriodList[j];
                element = this.toUpperCase(element);

                // “三轮冲刺”后台不建目录不传资源，客户端动态判断，没有目录没有资源时，此栏目不显示。
                if(element.RevisePeriodName === "三轮冲刺" && element.SeriesCount <= 0){
                  element.isHide = true;
                }
            }
        }
        if(this.isValidArray(data[0].RevisePeriodList)){
          this.periodList = data[0].RevisePeriodList
        }
      })
    },
    onClickPeriod(item,index){
      this.periodIndex = index
      this.$nextTick(()=>{
        this.$refs.series.getNewSCSeriesList()
      })
    }
  }
}
</script>

<style lang='less' scoped>
.GkfxIndex{
  padding-left: 80px;
}
.gkfx-header{
  height: 86px;
  width: 100%;
  background: #FFF;
  padding: 0 20px;
  margin-left: 15px;
  .line{
    line-height: 86px;
  }
  .gkfx-filter{
    cursor: pointer;
    display: inline-block;
    padding: 0 18px;
  }
}
</style>
